import React from 'react';
import './Header.css';
import JWDAlogo from './JWDA.png';
import { useTranslation } from 'react-i18next';

function Header() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <header className="header">
      <div className="header-content">
        <img src={JWDAlogo} alt="JWDA Logo" className="logo" />
        <div className="text-content">
          <h1 className="org-name">{t('org_name')}</h1>
          <p className="slogan">{t('slogan')}</p>
        </div>
        <div className="language-switch">
          <button
            className={i18n.language === 'en' ? 'active' : ''}
            onClick={() => changeLanguage('en')}
          >
            EN
          </button>
          <button
            className={i18n.language === 'he' ? 'active' : ''}
            onClick={() => changeLanguage('he')}
          >
            HE
          </button>
        </div>
      </div>
      <nav className="nav-links">
        <a href="/">{t('nav.home')}</a>
        <a href="/mission">{t('nav.mission')}</a>
        <a href="/interests">{t('nav.interests')}</a>
        <a href="/join">{t('nav.join')}</a>
      </nav>
    </header>
  );
}

export default Header;
