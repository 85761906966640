import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

function ArticlePage() {
  const { id } = useParams();
  const { t } = useTranslation();

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" component="h2" sx={{ marginBottom: 4 }}>
        {t(`articles.${id}.title`)}
      </Typography>
      <Typography variant="body1">
        {t(`articles.${id}.fullText`)}
      </Typography>
    </Box>
  );
}

export default ArticlePage;
