// src/components/JoinUsForm.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Chip, TextField, Autocomplete, Button, ThemeProvider, createTheme } from '@mui/material';
import './JoinUsForm.css';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9', // Customize the primary color if desired
    },
    background: {
      paper: '#424242', // Dark background for form inputs
      default: '#303030', // Darker background
    },
  },
});

function JoinUsForm() {
  const [formData, setFormData] = useState({
    name: '',
    mainCountry: '',
    otherCountries: [],
    languages: [],
    skills: [],
    otherSkills: '',
    contact: ''
  });

  const [countries, setCountries] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [skills] = useState(['Tech', 'Social Media', 'Combat', 'Community Engagement', 'Finance', 'Legal', 'Other']);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get('https://restcountries.com/v3.1/all');
        const countryList = response.data.map(country => country.name.common);
        setCountries(countryList);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    const fetchLanguages = () => {
      const languageList = ['English', 'Hebrew', 'Spanish', 'French', 'German', 'Russian', 'Italian', 'Arabic'];
      setLanguages(languageList);
    };

    fetchCountries();
    fetchLanguages();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name !== 'otherCountries' && name !== 'languages' && name !== 'skills') {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleMultiChange = (name) => (event, newValue) => {
    setFormData({ ...formData, [name]: newValue });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, mainCountry, otherCountries, languages, skills, otherSkills, contact } = formData;

    fetch('https://formspree.io/f/xzzbrwyb', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ name, mainCountry, otherCountries, languages, skills, otherSkills, contact })
    })
      .then((response) => {
        if (response.ok) {
          alert('Your submission was successful!');
          setFormData({ name: '', mainCountry: '', otherCountries: [], languages: [], skills: [], otherSkills: '', contact: '' }); // Reset form
        } else {
          alert('There was a problem with your submission.');
        }
      })
      .catch((error) => {
        alert('There was a problem with your submission.');
      });
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="join-us-form">
        <h2>Join Us</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="mainCountry">Main Country:</label>
            <TextField
              select
              fullWidth
              id="mainCountry"
              name="mainCountry"
              value={formData.mainCountry}
              onChange={handleChange}
              required
              SelectProps={{
                native: true,
              }}
            >
              <option value="">Select your country</option>
              {countries.map((country) => (
                <option key={country} value={country}>{country}</option>
              ))}
            </TextField>
          </div>
          <div className="form-group">
            <label htmlFor="otherCountries">Other Countries:</label>
            <Autocomplete
              multiple
              options={countries}
              getOptionLabel={(option) => option}
              onChange={handleMultiChange('otherCountries')}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Select other countries" placeholder="Countries" />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                ))
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="languages">Languages:</label>
            <Autocomplete
              multiple
              options={languages}
              getOptionLabel={(option) => option}
              onChange={handleMultiChange('languages')}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Select languages" placeholder="Languages"  />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                ))
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="skills">Relevant Skills:</label>
            <Autocomplete
              multiple
              options={skills}
              getOptionLabel={(option) => option}
              onChange={handleMultiChange('skills')}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Select skills" placeholder="Skills"  />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                ))
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="otherSkills">Other Skills (optional):</label>
            <textarea
              id="otherSkills"
              name="otherSkills"
              value={formData.otherSkills}
              onChange={handleChange}
              placeholder="Please specify any other skills..."
            />
          </div>
          <div className="form-group">
            <label htmlFor="contact">Contact Information:</label>
            <input
              type="text"
              id="contact"
              name="contact"
              value={formData.contact}
              onChange={handleChange}
              placeholder="Phone number or other contact method"
            />
          </div>
          <Button type="submit" variant="contained" color="primary" className="submit-button">Submit</Button>
        </form>
      </div>
    </ThemeProvider>
  );
}

export default JoinUsForm;
