import React from 'react';
import './Mission.css';
import { useTranslation } from 'react-i18next';

function Mission() {
  const { t } = useTranslation();

  return (
    <div className="mission-page">
      <section className="mission-intro">
        <h2>{t('mission.title')}</h2>
        <p>{t('mission.intro.paragraph')}</p>
      </section>

      <section className="proactive-actions card">
        <h3>{t('mission.proactive_actions.title')}</h3>
        <p>{t('mission.proactive_actions.paragraph')}</p>
      </section>

      <section className="network-of-individuals card">
        <h3>{t('mission.network_of_individuals.title')}</h3>
        <p>{t('mission.network_of_individuals.paragraph')}</p>
      </section>

      <section className="collaboration card">
        <h3>{t('mission.collaboration.title')}</h3>
        <p>{t('mission.collaboration.paragraph')}</p>
      </section>

      <section className="cyber-defense card">
        <h3>{t('mission.cyber_defense.title')}</h3>
        <p>{t('mission.cyber_defense.paragraph')}</p>
      </section>
    </div>
  );
}

export default Mission;
