import React from 'react';
import { Link } from 'react-router-dom';
import './Join.css';
import { useTranslation } from 'react-i18next';

function JoinUs() {
  const { t } = useTranslation();

  return (
    <div className="join-us">
      <section className="intro">
        <h2>{t('join_us.title')}</h2>
        <p>{t('join_us.intro.paragraph')}</p>
      </section>

      <section className="call-for-skills card">
        <h3>{t('join_us.call_for_skills.title')}</h3>
        <p>{t('join_us.call_for_skills.paragraph')}</p>
        <ul>
          {t('join_us.call_for_skills.skills', { returnObjects: true }).map((skill, index) => (
            <li key={index}>
              <strong>{skill.title}</strong> {skill.description}
            </li>
          ))}
        </ul>
      </section>

      <section className="how-to-join card">
        <h3>{t('join_us.how_to_join.title')}</h3>
        <p>{t('join_us.how_to_join.paragraph')}</p>
        <Link to="/join-us-form" className="cta-button">{t('join_us.how_to_join.cta', 'Submit')}</Link>
      </section>

      <section className="join-us-note card">
        <h3>{t('join_us.join_us_note.title')}</h3>
        <p>{t('join_us.join_us_note.paragraph')}</p>
      </section>
    </div>
  );
}

export default JoinUs;
