import React from 'react';
import { Box, Card, CardContent, CardMedia, Grid, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Interests.css';

const articles = [
];

function Interests() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleReadMore = (articleId) => {
    navigate(`/articles/${articleId}`);
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" component="h2" sx={{ textAlign: 'center', marginBottom: 4 }}>
        {t('articles.header', 'Articles of Interest')}
      </Typography>
      <Grid container spacing={4}>
        {articles.map((article) => (
          <Grid item xs={12} sm={6} md={4} key={article.id}>
            <Card>
              <CardMedia
                component="img"
                height="200"
                image={article.image}
                alt={`${t(`articles.${article.id}.title`)} image`}
              />
              <CardContent>
                <Typography variant="h6" component="div" gutterBottom>
                  {t(`articles.${article.id}.title`)}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {t(`articles.${article.id}.summary`)}
                </Typography>
                <Button
                  size="small"
                  onClick={() => handleReadMore(article.id)}
                  sx={{ marginTop: 2 }}
                >
                  {t('articles.readMore', 'Read More')}
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default Interests;
