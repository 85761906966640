import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import { useTranslation } from 'react-i18next';

function Home() {
  const { t } = useTranslation();

  return (
    <div className="home">
      <section className="hero">
        <h2>{t('home.hero.title')}</h2>
        <p className="tagline">{t('home.hero.tagline')}</p>
        <Link to="/mission" className="cta-button">{t('home.hero.cta')}</Link>
      </section>

      <section className="mission card">
        <h3>{t('home.mission.title')}</h3>
        <p>{t('home.mission.paragraph1')}</p>
        <p>{t('home.mission.paragraph2')}</p>
      </section>

      <section className="values card">
        <h3>{t('home.values.title')}</h3>
        <ul>
          {t('home.values.values_list', { returnObjects: true }).map((value, index) => (
            <li key={index}>
              <strong>{value.title}</strong> {value.description}
            </li>
          ))}
        </ul>
      </section>

      <section className="get-involved card">
        <h3>{t('home.get_involved.title')}</h3>
        <p>{t('home.get_involved.description')}</p>
        <Link to="/join" className="cta-button">{t('home.get_involved.cta')}</Link>
      </section>
    </div>
  );
}

export default Home;
