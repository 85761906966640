// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Mission from './pages/Mission';
import JoinUs from './pages/Join';
import Interests from './pages/Interests';
import ArticlePage from './pages/ArticlePage';
import JoinUsForm from './components/JoinUsForm';


function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Mission" element={<Mission />} />
          <Route path="/Join" element={<JoinUs />} />
          <Route path="/join-us-form" element={<JoinUsForm />} />
          <Route path="/Interests" element={<Interests />} />
          <Route path="/articles/:id" element={<ArticlePage />} /> {/* מסלול למאמר מלא */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
